import React, { useState, useEffect } from "react";
import type { CategoryType } from "../../types";

interface Props {
  result: CategoryType | null;
}

const ResultSection: React.FC<Props> = ({ result }) => {
  const [selectedTab, setSelectedTab] = useState<CategoryType>("Credit Repair");

  useEffect(() => {
    if (result) {
      setSelectedTab(result);
    }
  }, [result]);

  const resultDetails: {
    [key in CategoryType]: {
      color: string;
      bgColor: string;
      title: string;
      description: string;
      actionPlan: string[];
      immediateInsights: string[];
    };
  } = {
    "Credit Repair": {
      color: "text-red-600",
      bgColor: "bg-red-50",
      title: "Credit Repair Recommended",
      description:
        "Your responses suggest you may benefit from focused credit repair strategies.",
      actionPlan: [
        "Obtain and review your credit report: Request a free copy of your credit report from major credit bureaus. Check for errors or negative listings that may be impacting your score.",
        "Dispute Inaccuracies: If you find errors, dispute them directly with the credit bureaus. Follow up to ensure corrections are made.",
        "Improve your payment history: Set up automatic payments to avoid late fees and missed payments. Pay down outstanding debts to reduce your credit utilisation ratio.",
      ],
      immediateInsights: [
        "Credit Challenges Identified: Your quiz responses indicate potential issues affecting your credit score.",
        "Importance of Credit Health: A strong credit score opens doors to better financial opportunities and lower interest rates.",
      ],
    },
    "Debt Consolidation": {
      color: "text-yellow-600",
      bgColor: "bg-yellow-50",
      title: "Debt Consolidation Suggested",
      description:
        "Your answers indicate debt consolidation could help manage your financial obligations.",
      actionPlan: [
        "Assess Your Debts: List all your current debts, interest rates, and monthly payments. Identify high-interest debts that are costing you the most.",
        "Explore Consolidation Options: Research loans that can combine your debts into a single payment. Compare different lenders to find the best interest rates and terms.",
        "Optimise Your Budget: Track your expenses to identify areas where you can cut back. Use budgeting tools or apps to help manage your finances effectively.",
      ],
      immediateInsights: [
        "Simplify Your Debt Management: Your responses suggest that consolidating your debts could make repayments more manageable.",
        "Potential Savings: Debt consolidation may reduce your overall interest rates and monthly payments.",
      ],
    },
    "Good Financial Standing": {
      color: "text-green-600",
      bgColor: "bg-green-50",
      title: "Good Financial Standing",
      description:
        "Congratulations! You're effectively managing your finances.",
      actionPlan: [
        "Set new financial goals: Consider investing, saving for retirement, or other wealth-building strategies. Plan for major life events or purchases with long-term financial planning.",
        "Continue good financial habits: Keep monitoring your credit report regularly. Maintain your budgeting practices to sustain your financial stability.",
        "Expand Your Financial Knowledge: Stay informed about market trends and new investment opportunities. Seek advanced financial education to further enhance your skills.",
      ],
      immediateInsights: [
        "Strong Financial Health: Your answers indicate that you're effectively managing your finances.",
        "Opportunities for Growth: This is an ideal time to consider expanding your financial horizons.",
      ],
    },
  };

  const renderContent = (category: CategoryType) => {
    const details = resultDetails[category];

    return (
      <div
        className={`${details.color} ${details.bgColor} p-4 sm:p-6 md:p-8 rounded-xl text-center space-y-6`}
      >
        {/* Title */}
        <h2 className="text-lg sm:text-xl md:text-3xl font-bold">
          {details.title}
        </h2>

        {/* Description */}
        <p className="text-sm sm:text-base md:text-lg leading-relaxed">
          {details.description}
        </p>

        {/* Immediate Insights Section */}
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md space-y-4">
          <h3 className="text-base sm:text-lg md:text-2xl font-semibold">
            Immediate Insights
          </h3>
          <ul className="space-y-3 text-left text-sm sm:text-base">
            {details.immediateInsights.map((insight, index) => (
              <li key={index} className="flex items-start">
                <span className="mr-3">💡</span>
                <span>{insight}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Action Plan Section */}
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md space-y-4">
          <h3 className="text-base sm:text-lg md:text-2xl font-semibold">
            Action Plan
          </h3>
          <ul className="space-y-3 text-left text-sm sm:text-base">
            {details.actionPlan.map((action, index) => (
              <li key={index} className="flex items-start">
                <span className="mr-3">📌</span>
                <span>{action}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Additional Content for Non-Good Financial Standing */}

        {category !== "Good Financial Standing" && (
          <div className="mt-6 bg-white p-4 sm:p-6 rounded-lg shadow-md space-y-4">
            <h3 className="text-base sm:text-lg md:text-2xl font-semibold mb-4">
              Enhance Your Progress with Our Financial Freedom Membership
            </h3>
            <ul className="space-y-3 text-left list-disc list-inside">
              <li className="flex items-start">
                <span className="mr-3">●</span>
                DIY Credit Repair Course: Learn effective strategies to repair
                and improve your credit resources.
              </li>
              <li className="flex items-start">
                <span className="mr-3">●</span>
                Credit Repair Ebooks, Templates & Checklists: Access
                step-by-step guides to navigate the credit repair process.
              </li>
              <li className="flex items-start">
                <span className="mr-3">●</span>
                Personalised Support: Get expert advice tailored to your unique
                situation.
              </li>
            </ul>
          </div>
        )}
        <div className="mt-8 bg-white p-6 sm:p-8 rounded-lg shadow-md space-y-6">
          {/* Section Title */}
          <h3 className="text-base sm:text-lg md:text-2xl font-semibold text-black text-center">
            Next Steps
          </h3>

          {/* Description */}
          <p className="sm:text-lg leading-relaxed text-gray-800">
            We recommend focusing on the highlighted section above to address
            your immediate needs. Our Financial Freedom Membership Program
            offers resources and support tailored to help you achieve your
            specific financial goals.
          </p>

          {/* Membership Call-to-Action */}
          <div className="bg-gray-50 p-6 rounded-lg space-y-4">
            <h4 className="text-base sm:text-lg md:text-2xl font-semibold text-black">
              Join the Financial Freedom Membership Today
            </h4>
            <ul className="text-left text-sm sm:text-base text-gray-700 space-y-3">
              <li className="flex items-start">
                <span className="mr-3">●</span>
                No Risk: Cancel anytime with no long-term commitments.
              </li>
              <li className="flex items-start">
                <span className="mr-3">●</span>Affordable Access: Gain valuable
                resources without straining your budget.
              </li>
              <li className="flex items-start">
                <span className="mr-3">●</span>
                Personalized Support: Receive guidance tailored to your
                financial situation.
              </li>
            </ul>
          </div>

          <div className="text-center mt-6">
            {/* <a
              href="https://www.mycreditfix.co.za/membership-program/"
              className="inline-block px-6 py-3 text-2xl font-extrabold rounded-md  text-white underline bg-black hover:bg-gray-800"
            >
              Click Here to Get Started
            </a> */}
            <a
              href="#"
              onClick={() =>
                (window.parent.location.href =
                  "https://www.mycreditfix.co.za/membership-program/")
              }
              className="inline-block px-6 py-3 text-2xl font-extrabold rounded-md text-white underline bg-black hover:bg-gray-800"
            >
              Click Here to Get Started
            </a>
          </div>
        </div>
      </div>
    );
  };

  if (!result) return null;

  return (
    <div className="w-full flex flex-col">
      <div className="border-b border-gray-200 flex justify-evenly">
        <nav className="flex flex-wrap" aria-label="Tabs">
          {(Object.keys(resultDetails) as CategoryType[]).map((category) => (
            <button
              key={category}
              onClick={() => setSelectedTab(category)}
              className={`w-1/3 md:w-auto py-4 px-2 md:px-4 text-center border-b-2 font-medium text-sm
                ${
                  selectedTab === category
                    ? `border-${
                        resultDetails[category].color.split("-")[1]
                      }-500 ${resultDetails[category].color}`
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }`}
            >
              {category}
            </button>
          ))}
        </nav>
      </div>
      <div className="mt-6 md:mt-8 flex flex-col justify-between">
        {renderContent(selectedTab)}
      </div>
    </div>
  );
};

export default ResultSection;
